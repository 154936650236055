<template>
  <Content>
    <template v-slot:topbar>
      <AddWarehouse />
    </template>

    <template v-slot:content>
      <template v-if="warehouses && warehouses.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="item in warehouses.data" :key="item.id">
              <SingleWarehouse :item="item" />
            </template>
          </ul>
        </div>

        <Pagination :meta="warehouses.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddWarehouse from "@/components/Settings/CompanySettings/Warehouses/AddWarehouse";
import SingleWarehouse from "@/components/Settings/CompanySettings/Warehouses/SingleWarehouse";
import Pagination from "@/components/Components/Pagination";

export default {
  name: "Warehouses",
  components: {
    AddWarehouse,
    SingleWarehouse,
    Pagination,
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchWarehouses()
    } else {
      this.$store.dispatch('getWarehouses', Search.getCurrentPage())
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchWarehouses()
    }
  },
  methods: {
    searchWarehouses: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getWarehouses', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getWarehouses', page);
      Search.changePage(this.warehouses.meta.current_page)
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddWarehouseForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>