<template>
  <li>
    <item-card :content="false">
      <template v-slot:title>
        <template v-if="editableItem.id !== item.id">
          <div class="flex flex-wrap">
            <item-text title="Nosaukums" :text="item.name" />

          <template v-if="item.branch">
              <item-text title="Filiāle" :text="item.branch.name" />
          </template>
          </div>
        </template>
      </template>
      <template v-slot:buttons>

        <template v-if="editableItem.id !== item.id">

          <Button icon="pencil" @click="editItem(item)" />

          <Button icon="delete" @click="deleteWarehouse(item.id)" />

        </template>
      </template>

      <template v-slot:content>
      </template>
      <template v-slot:additional>

        <template v-if="editableItem.id === item.id">
          <EditWarehouse :item="item"/>
        </template>

      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditWarehouse from "@/components/Settings/CompanySettings/Warehouses/EditWarehouse"

export default {
  name: "SingleWarehouse",
  components: {
    ItemCard,
    ItemText,
    EditWarehouse,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  methods: {
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteWarehouse(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteWarehouse', itemId)
      }
    }
  }
}
</script>
