<template>
  <Modal :size="'md'" :showModal="showModal">
    <template v-slot:button>
      <div class="flex h-full items-center">
        <button
          @click="showModal = true"
          class="
            rounded-full
            w-12
            h-12
            bg-gray-200
            dark:bg-main4
            flex
            justify-center
            items-center
            leading-none
            hover:bg-gray-300
            transition
            duration-300
            ease-in-out
            dark:text-gray-100
          "
        >
          <PlusIcon class="w-7 h-7" />
        </button>
      </div>
    </template>

    <template v-slot:content>
      <form class="space-y-6 w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <Input
              name="name"
              placeholder="Noliktavas nosaukums"
              v-model="form.name"
              :errors="errors.name"
            />
          </div>
          <div class="col-span-1">
            <Select
              placeholder="Filiāle"
              :items="inputData.branches"
              v-model="form.branch"
              :errors="errors.branch"
            />
          </div>
        </div>

        <div class="">
          <div class="flex justify-end">
            <button
              type="button"
              class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
              @click="hideAddItemForm"
            >
              Atcelt
            </button>

            <template v-if="!loading">
              <button
                type="button"
                class="
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-gradient-to-r
                  from-button2-from
                  to-button2-to
                  hover:from-button2-to hover:to-button2-from
                  focus:outline-none focus:border-none
                  transition
                  duration-500
                  ease-in-out
                "
                @click.prevent="submit"
              >
                Pievienot
              </button>
            </template>
            <template v-else>
              <Loading />
            </template>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Components/Modal";
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "AddWarehouse",
  components: {
    Modal,
    Input,
    Select,
    Loading,
    PlusIcon,
  },
  data: () => ({
    form: {
      name: "",
      branch: null,
    },
    showModal: false,
  }),
  watch: {
    showModal() {
      this.form = {
        name: "",
        branch: null,
      };
    },
  },
  created() {
    this.$store.dispatch("getWarehouseInputData");
  },
  computed: {
    ...mapGetters({
      inputData: "warehouseInputData",
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        name: {
          rules: ["required"],
        },
      };
    },
  },
  methods: {
    hideAddItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
      this.showModal = false;
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store
          .dispatch("createNewWarehouse", {
            name: this.form.name,
            branch_id: this.form.branch ? this.form.branch.id : null,
          })
          .then(() => {
            this.showModal = false;
          });
      } else {
        this.$Progress.fail();
      }
    },
  },
};
</script>